<template>
  <InnerPageLayout>
    <TopSegmentsPanel></TopSegmentsPanel>
    <div class="table-container flex flex-col">
      <SegmentsTable :segments="segments" />
      <EmptyTableMessage />
    </div>
  </InnerPageLayout>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";

import InnerPageLayout from "@/widgets/layouts/InnerPageLayout.vue";
import TopSegmentsPanel from "@/entities/segments/TopSegmentsPanel.vue";
import SegmentsTable from "@/entities/segments/SegmentsTable.vue";
import EmptyTableMessage from "@/entities/segments/EmptyTableMessage.vue";

const router = useRouter();
const segments: object[] = [];
</script>

<style lang="scss" scoped>
.table-container {
  position: relative;
  top: 77px;
  height: calc(100vh - 77px - 72px - 12px);
  max-height: calc(100vh - 77px - 72px - 12px);

  background-color: white;
  border: 1px solid #edecef;
  border-radius: 8px;

  padding: 8px 20px;
}

::v-deep(.p-component) {
  font-family: $main-font-family;
}
</style>
