<template>
  <div class="top-sidebar-container">
    <div class="top-sidebar">
      <img src="@/assets/images/logo_black.svg" alt="logo" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    padding: 12px;
    background-color: $page-background-color;

    z-index: 1000;

}

.top-sidebar {
  display: flex;
  align-items: center;

  height: 48px;

  border-radius: 8px;
  background-color: white;

  padding: 8px 16px;

  img {
    height: 17px;
  }
}
</style>
