<template>
  <div class="panel-container">
    <div class="panel">
      <BreadCrumbs :items="items" />
      <SearchInput
        placeholder="Поиск по бренду, рекламной кампании"
        class="search-input"
      />
      <Button
        icon="pi pi-filter"
        severity="secondary"
        aria-label="Bookmark"
        class="m-l-12 f-s-0"
      />
      <Button
        icon="pi pi-inbox"
        severity="secondary"
        aria-label="Bookmark"
        class="m-l-12 f-s-0"
      />
      <Button
        label="Создать сегмент"
        class="segment-create-button m-l-16 m-r-16"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import BreadCrumbs from "@/shared/menu/BreadCrumbs.vue";
import SearchInput from "@/shared/forms/SearchInput.vue";
import Button from "primevue/button";

const items = ref([{ label: "Сегмент" }, { label: "Список сегментов" }]);
</script>

<style lang="scss" scoped>
.panel-container {
  height: 57px;

  position: fixed;
  top: 72px;
  left: 252px;
  right: 12px;
  min-width: 743px;

  background-color: $page-background-color;

  z-index: 1000;
}

.panel {
  height: 100%;

  display: flex;
  align-items: center;

  background-color: white;
  border-radius: 8px;
}

.segment-create-button {
  flex-basis: 240px;
  flex-shrink: 1;
}

.search-input {
  flex: 1;
  flex-basis: 250px;
  flex-shrink: 0;
}
</style>
