import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

import ListSegmentsPage from '../pages/segments/ListSegmentsPage.vue'
import ListCampaignsPage from '../pages/campaigns/ListCampaignsPage.vue'

import LoginPage from '../pages/auth/LoginPage.vue'
import PrivacyPolicyPage from '../pages/auth/PrivacyPolicyPage.vue'
import PersonalDataPage from '../pages/auth/PersonalDataPage.vue'



import { useAuthStore } from '@/store/auth/login'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: "listSegments",
    component: ListSegmentsPage,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: PrivacyPolicyPage
  },
  {
    path: "/personal-data-policy",
    name: "personalDataPolicy",
    component: PersonalDataPage
  },
  {
    path: '/campaigns',
    name: "listCampaigns",
    component: ListCampaignsPage,
    meta: {
      requiresAuth: true
    },
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const store = useAuthStore();

  if (to.name == "login" && store.isLoggedIn) {
    next(from)
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !store.isLoggedIn) {
    next({ name: 'login', query: { next: to.name?.toString() } });
  } else {
    next();
  }
})

export default router
