<template>
    <InnerPageLayout>
        <div>Кампании</div>
    </InnerPageLayout>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';

import InnerPageLayout from '@/widgets/layouts/InnerPageLayout.vue';
import Button from 'primevue/button';


const router = useRouter();


</script>