import { defineStore } from 'pinia'
import { rootGetters } from '@/main'

const get_user_info_url = "/users/me"
const login_url = "/auth/login"
const logout_url = "/auth/logout"

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: null as UserInfo | null,
        isLogged: false,
    }),
    getters: {
        // добавить store.user, когда будет использоваться инфа из юзера
        isLoggedIn: (state) => state.isLogged || Boolean(localStorage.getItem("access_token"))
    },
    actions: {
        async getUserInfo() {
            await rootGetters.api.client
                .get(get_user_info_url)
                .then((response) => {
                    this.user = response.data
                })
                .catch((err) => {
                    rootGetters.errorHandler.showError(err);
                })
        },
        async login(crendetails: LoginInfo) {
            await rootGetters.api.client.post(login_url, crendetails,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json'
                    }
                }
            )
                .then((response) => {
                    this.setTokens(response.data)
                    this.getUserInfo()
                })
                .catch((err) => {
                    if (err.response && err.response.status == 400)
                        throw err;

                    rootGetters.errorHandler.showError(err);
                })
        },
        async logout() {
            await rootGetters.api.client
                .post(logout_url)
                .catch((err) => {
                    rootGetters.errorHandler.showError(err);
                })
                .finally(() => {
                    this.removeTokens()
                })
        },
        setTokens(response: TokenResponse) {
            localStorage.setItem('access_token', response.access_token)
            localStorage.setItem('token_type', response.token_type)
            this.isLogged = true;
        },
        removeTokens() {
            localStorage.removeItem("access_token")
            localStorage.removeItem("token_type")
            this.isLogged = false;
        }

    },
})

interface UserInfo {
    id: number
    email: string
    is_active: boolean
    is_superuser: boolean
    is_verified: boolean
    fname: string
    lname: string
    // roles: Array<string>
}

interface LoginInfo {
    username: string
    password: string
}

interface TokenResponse {
    access_token: string
    token_type: string
}