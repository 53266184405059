<template>
  <IconField iconPosition="left">
    <InputIcon class="pi pi-search"> </InputIcon>
    <InputText v-model="value" :placeholder="placeholder" />
  </IconField>
</template>

<script setup lang="ts">
import { ref } from "vue";

import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";

const value = ref("");
defineProps<{
  placeholder?: string;
}>();
</script>

<style lang="scss" scoped>
::v-deep(.p-inputtext) {
  width: 100%;
}
</style>
